import { render, staticRenderFns } from "./ArticleBarCode.vue?vue&type=template&id=5e309169&scoped=true&"
import script from "./ArticleBarCode.vue?vue&type=script&lang=js&"
export * from "./ArticleBarCode.vue?vue&type=script&lang=js&"
import style0 from "./ArticleBarCode.vue?vue&type=style&index=0&id=5e309169&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e309169",
  null
  
)

export default component.exports